@import '~antd/dist/antd.css';
@import '~react-datepicker/dist/react-datepicker.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import 'react-sortable-tree.scss';
@import '~emoji-mart/css/emoji-mart.css';
@import 'react-calendar.scss';

::selection {
  background: var(--theme-primary);
  box-shadow: 0 0 0 1px var(--theme-focus) !important;
  outline: none;
}

:focus {
  box-shadow: 0 0 0 1px var(--theme-focus);
  outline: none;
}

html {
  --theme-dark: #2777f4; // theme dark color
  --theme-primary: #222222; // theme primary color
  --theme-light: #1897f8; // theme light color
  --theme-disabled: #c5d5f4; // the theme disabled color
  --theme-hover-light: #dde4fc; // theme hover light color
  --theme-task-no-priority: #bcbac6;
  --theme-task-low-priority: #61befc;
  --theme-task-middle-priority: #feab4c;
  --theme-task-top-priority: #fb4e87;
  --theme-task-due: #f56798;
  --theme-task-today: #84bc4a;
  --theme-task-tomorrow: #755eb5;
  --theme-task-this-week: #feb259;
  --theme-task-next-week: #6ebffc;
  --theme-focus: #bdc1c6;
  --theme-secondary-dark: gray;
  --theme-secondary-main: #abafbb;
  --theme-secondary-light: #f1f2f6;
  --theme-delete-main: #fa4d88;
  --theme-delete-light: #fc6899;
}

body {
  --antd-wave-shadow-color: var(--theme-primary);
}

.hide-display {
  display: none;
}

.disable {
  pointer-events: none;
}

.focus-visible + .ant-checkbox-inner {
  outline: 1px solid var(--theme-focus);
}

.focus-visible {
  box-shadow: 0 0 0 1px var(--theme-focus);
  outline: none;
}

.rst__collapseButton:focus,
.rst__expandButton:focus {
  background-color: var(--theme-primary);
}

.ant-message-notice-content {
  border-radius: 5px;
}

.ant-btn:focus {
  box-shadow: 0 0 0 2px #b4b4b433 !important;
  outline: none;
}

.ant-btn-primary {
  background: var(--theme-primary);
  border-color: var(--theme-primary);
  border-radius: 5px;
  color: white;

  &:focus {
    background: var(--theme-dark) !important;
    border-color: var(--theme-dark) !important;
    color: white !important;
  }

  &:disabled {
    background: var(--theme-disabled) !important;
    border-color: var(--theme-disabled) !important;
    color: white !important;
  }

  &:hover {
    background: var(--theme-light) !important;
    border-color: var(--theme-light) !important;
    color: white !important;
  }
}

.ant-menu-item-selected {
  color: var(--theme-primary);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--theme-light);
}

.ant-input-affix-wrapper {
  &:hover {
    border-color: var(--theme-focus);
  }

  &:focus {
    border-color: var(--theme-focus);
  }
}

.ant-input:hover {
  border-color: var(--theme-focus);
}

.ant-input:focus,
.ant-input-focused {
  border-color: var(--theme-focus);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: var(--theme-focus) !important;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px #b4b4b433 !important;
  outline: none;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
}

.ant-checkbox-checked::after {
  border: 1px solid var(--theme-primary);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--theme-primary);
}

.ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: var(--theme-primary) !important;
  color: white !important;
}

.ant-picker {
  border-radius: 5px;
}

.ant-btn:hover,
.ant-btn:focus {
  background: #fff;
  border-color: var(--theme-primary);
  color: var(--theme-primary);
}

.ant-layout-header {
  background: var(--theme-dark);
}

.ant-spin {
  color: var(--theme-primary);
}

.ant-spin-blur {
  opacity: 0;
}

.ant-spin-dot-item {
  background: var(--theme-primary);
}

a {
  color: var(--theme-primary);
}

a:hover {
  color: var(--theme-light);
}

.ant-menu-item a:hover,
.ant-menu-item:hover {
  color: var(--theme-primary);
}

.ant-modal-body {
  background: var(--theme-secondary-light);
}

.ant-modal-header {
  padding: 22px 24px;
}

.ant-modal-footer {
  padding: 16px;

  .ant-btn {
    border: unset;
    box-shadow: none;
    color: var(--theme-secondary-dark);

    &:hover {
      color: var(--theme-secondary-main);
    }

    &:disabled {
      background: #f5f5f5 !important;
    }
  }

  .ant-btn-primary {
    background-color: var(--theme-primary);
    border-radius: 5px;
    color: white;

    &:hover {
      background-color: var(--theme-light);
      color: white;
    }

    &:disabled {
      background-color: var(--theme-disabled) !important;
    }
  }
}

.ant-switch-checked {
  background-color: var(--theme-primary);
}

.ant-modal-content {
  border-radius: 15px;
  overflow: auto;
}

.ant-select-item-option {
  border-radius: 5px;
  font-weight: 300;
  margin: 3px 15px;
}

.ant-select-selector {
  border-radius: 5px !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #bbbbc7;
  color: #fff;
  font-weight: 300;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--theme-primary);
}

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: var(--theme-primary);
  box-shadow: 0 0 0 2px #f3f3f3;
}

.ant-select-dropdown {
  border-radius: 5px;
}

.ant-input-number,
.ant-input-number-handler-wrap {
  border-radius: 5px;
}

.ant-input-number:hover {
  border-color: var(--theme-primary);
}

.ant-input-number:focus,
.ant-input-number-focused {
  border-color: var(--theme-primary);
  box-shadow: 0 0 0 2px rgba(243, 243, 243, 0.2);
}

.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: var(--theme-primary);
}

.ant-picker-focused {
  border-color: var(--theme-primary);
  box-shadow: 0 0 0 2px rgb(238, 238, 238);
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: var(--theme-primary);
}

.ant-picker-time-panel-column
  > .ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: var(--theme-light);
  color: #fff;
}

.ant-tooltip-inner {
  border-radius: 5px;
  font-size: 0.8rem;
}

// priority based custom antd checkbox

/* stylelint-disable no-descending-specificity */

/* top priority styles */
.top-priority {
  .ant-checkbox-inner {
    background-image: url('/top-priority.svg');
    background-size: cover;
    border: 1px solid var(--theme-task-top-priority) !important;
    border-color: var(--theme-task-top-priority) !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid var(--theme-task-top-priority);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--theme-task-top-priority);
  }
}

/* middle priority styles */
.middle-priority {
  .ant-checkbox-inner {
    background-image: url('/middle-priority.svg');
    background-size: cover;
    border: 1px solid var(--theme-task-middle-priority) !important;
    border-color: var(--theme-task-middle-priority) !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid var(--theme-task-middle-priority);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--theme-task-middle-priority);
  }
}

/* low priority styles */
.low-priority {
  .ant-checkbox-inner {
    background-image: url('/low-priority.svg');
    background-size: cover;
    border: 1px solid var(--theme-task-low-priority) !important;
    border-color: var(--theme-task-low-priority) !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid var(--theme-task-low-priority);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--theme-task-low-priority);
  }
}

/* no priority styles */
.no-priority {
  .ant-checkbox-inner {
    background-image: url('/no-priority.svg');
    background-size: cover;
    border: 1px solid var(--theme-task-no-priority) !important;
    border-color: var(--theme-task-no-priority) !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid var(--theme-task-no-priority);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--theme-task-no-priority);
  }
}

/* stylelint-enable no-descending-specificity */

/** emoji mart related styles */
// disable emoji preview
.emoji-mart-preview {
  display: none;
}

.emoji-mart-emoji {
  span {
    cursor: pointer !important;
    height: 20px !important;
    width: 20px !important;
  }
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: var(--theme-primary);
}

.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value {
  color: var(--theme-primary);
}

.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date-today,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date-today {
  background: var(--theme-hover-light);
}

.border-padding-override {
  border: 1px solid #d9d9d9 !important;
}
