.DaySection {
  &-container {
    background-color: white;

    &:hover .UpcomingDayBody-add-task {
      opacity: 1;
    }
  }

  &-header {
    align-items: center;
    background-color: white;
    display: flex;
    padding: 20px 0;
    position: sticky;
    top: 80px;
    width: 100%;
    z-index: 20;
  }

  &-day {
    background: var(--theme-secondary-main);
    border-radius: 5px;
    color: white;
    font-size: 1.3rem;
    height: 50px;
    margin-right: 15px;
    padding-top: 9px;
    text-align: center;
    width: 50px;
  }

  &-month {
    color: var(--theme-secondary-main);
    font-size: 1.1rem;
    margin-right: 15px;
  }

  &-others {
    border-bottom: 1px solid var(--theme-secondary-light);
    flex-grow: 1;
    font-size: 0.8rem;
    min-height: 23px;
  }

  &-body {
    min-height: 150px;
    padding: 10px 0 40px;

    @media screen and (min-width: 580px) {
      padding-left: 68px;
    }

    .TaskItem-container--first {
      margin-left: 16px;
      width: 285px;

      @media screen and (min-width: 380px) {
        width: 340px;
      }

      @media screen and (min-width: 580px) {
        width: 430px;
      }

      @media screen and (min-width: 800px) {
        width: 380px;
      }

      @media screen and (min-width: 900px) {
        width: 480px;
      }

      @media screen and (min-width: 1200px) {
        width: 680px;
      }
    }
  }

  &-add-task {
    color: var(--theme-secondary-main);
    cursor: pointer;
    position: absolute;
    right: 0;

    i {
      font-size: 10px;
      margin-right: 3px;
    }

    &:hover {
      color: var(--theme-primary);
    }
  }

  &-sticky &-day {
    background: var(--theme-primary);
  }
}
