.TodoTaskOptionsPanel {
  &-container {
    background-color: white;
    border: 0.5px solid #f0f0f0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    min-width: 150px;
    padding: 2px 0;

    .ant-divider {
      margin: 4px 0;
    }
  }

  &-item {
    align-items: center;
    border: unset;
    border-radius: 5px;
    box-shadow: none;
    box-sizing: border-box;
    color: #828282 !important;
    display: flex;
    font-size: 0.7rem;
    font-weight: 400;
    justify-content: flex-start !important;
    margin: 2px;
    transition: color 0.3s ease-in;

    i {
      color: #babac6;
      margin-right: 10px;
      transition: color 0.3s ease-in;
      width: 20px;
    }

    &:hover {
      background-color: #f9f9f9;
    }

    &:last-child {
      &:hover {
        color: #cc4643 !important;

        i {
          color: #cc4643;
        }
      }
    }
  }

  &-modal-container {
    .anticon {
      color: #fe4b87 !important;
    }

    .ant-btn {
      border-radius: 5px;
    }

    .ant-btn-primary {
      background: #fe4b87;
      border-color: #fe4b87;
      color: white;

      &:hover {
        background: #f50756;
        border-color: #f50756;
        color: white;
      }
    }
  }
}
