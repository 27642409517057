.MonthSection {
  &-header {
    background-color: white;
    border-bottom: 1px solid var(--theme-secondary-light);
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    min-height: 80px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 25;

    &-inner-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 10px;
      padding-top: 10px;
      width: 300px;

      @media screen and (min-width: 380px) {
        width: 350px;
      }

      @media screen and (min-width: 580px) {
        flex-direction: row;
        padding-bottom: 0;
        width: 500px;
      }

      @media screen and (min-width: 800px) {
        width: 450px;
      }

      @media screen and (min-width: 900px) {
        width: 550px;
      }

      @media screen and (min-width: 1200px) {
        width: 750px;
      }
    }
  }

  &-body {
    display: flex;
    justify-content: center;
    width: 100%;

    &-inner-container {
      width: 300px;

      @media screen and (min-width: 380px) {
        width: 350px;
      }

      @media screen and (min-width: 580px) {
        width: 500px;
      }

      @media screen and (min-width: 800px) {
        width: 450px;
      }

      @media screen and (min-width: 900px) {
        width: 550px;
      }

      @media screen and (min-width: 1200px) {
        width: 750px;
      }
    }
  }

  &-sticky {
    color: var(--theme-primary);
  }

  &-title {
    font-size: 1.4rem;
    font-weight: 700;

    &-text {
      cursor: pointer;
    }
  }

  &-btn-right {
    margin-right: 10px;
  }

  &-navigation {
    opacity: 0;

    .ant-btn {
      border-radius: 5px;
      color: var(--theme-secondary-dark);
    }

    .fa-chevron-down {
      margin-left: 10px;
    }
  }

  &-sticky &-navigation {
    opacity: 1;
  }

  &-datepicker {
    .react-datepicker__day-name {
      color: #424242;
      font-size: 0.7rem !important;
      font-weight: 500;
      text-transform: uppercase;
    }

    .react-datepicker {
      background-color: white;
      border: 1px solid #f0f0f0;
    }

    .react-datepicker__header {
      background: inherit;
      border: unset;
    }

    .react-datepicker__day {
      border-radius: 50% !important;
      color: var(--theme-secondary-dark);
    }

    .react-datepicker__day--selected {
      background: var(--theme-primary);
      color: white;
    }

    .react-datepicker__day--keyboard-selected {
      background: unset;
      border: 1px solid var(--theme-primary);
    }

    .react-datepicker__day--disabled {
      color: var(--theme-secondary-light) !important;
    }

    .react-datepicker__day--outside-month {
      color: #cfcfcf;
    }

    &-header {
      align-items: center;
      display: flex;
      padding: 5px 15px 10px;
    }

    &-label {
      color: black;
      flex-grow: 1;
      font-size: 0.8rem;
      font-weight: 600;
      text-align: left;
    }

    &-left {
      background: white;
      border: 1px solid #f0f0f0;
      border-radius: 3px 0 0 3px;
      color: var(--theme-secondary-main);
      cursor: pointer;

      i {
        font-size: 8px;
        padding: 3px;
      }

      &:hover {
        background: #f7f7f7;
      }

      &:disabled {
        background: var(--theme-secondary-light);
        cursor: default;
      }
    }

    &-right {
      background: white;
      border: 1px solid #f0f0f0;
      border-radius: 0 3px 3px 0;
      color: var(--theme-secondary-main);
      cursor: pointer;

      i {
        font-size: 8px;
        padding: 3px;
      }

      &:hover {
        background: #f7f7f7;
      }

      &:disabled {
        background: var(--theme-secondary-light);
        cursor: default;
      }
    }
  }

  &-interval {
    &-select {
      min-width: 80px;

      .ant-select-selection-item {
        color: var(--theme-secondary-dark);
        text-transform: capitalize;
      }
    }

    &-dropdown {
      min-width: 140px !important;

      .ant-select-item-option-selected {
        background: unset;
        color: black;
        font-weight: 600;
      }

      .ant-select-item {
        text-transform: capitalize;
      }
    }
  }
}
