.withTodoLayout {
  &-sider {
    background-color: var(--theme-secondary-light);
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 25px 0 60px;
    position: fixed;
    width: 0;

    @media screen and (min-width: 800px) {
      min-width: 300px;
      width: 300px;
    }

    .rst__tree {
      transform: unset;
    }

    .rst__rowLandingPad::before,
    .rst__rowCancelPad::before {
      background-color: inherit;
      border: 1px dashed white;
      border-radius: 5px;
      box-shadow: none;
      margin-left: 20px;
      width: 240px;
      z-index: 0;
    }

    &-inner {
      .withFolderLayout-container:nth-child(4) .withFolderLayout-add-icon {
        display: none !important;
      }
    }
  }

  &-content {
    background-color: white;
    box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.103);
    display: flex;
    height: calc(100vh - 60px);
    justify-content: center;
    margin-left: 0;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 20px;
    width: 100%;
    z-index: 1;

    @media screen and (min-width: 800px) {
      margin-left: 300px;
    }

    &-background {
      background: #f5f4f9;
      display: flex;
      flex-grow: 1;
    }
  }

  &-header {
    background-color: white;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-grow: 1;
    height: 60px;
    padding: 0;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 200;
  }

  &-layout-container {
    &--disabled {
      pointer-events: none;
    }
  }

  &-sider-open {
    min-width: 300px;
    width: 100%;
    z-index: 100;

    @media screen and (min-width: 400px) {
      width: 300px;
    }
  }
}
